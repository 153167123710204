import React from "react"

import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

import { useRedditClient, useIsLoggedIn } from "../utils/hooks"

const UserSubscriptions = ({
  onUpdate,
  sortType = "top",
  timeFilter = "day",
}) => {
  const client = useRedditClient()
  const isloggedin = useIsLoggedIn()
  const [subscriptions, setSubscriptions] = React.useState([])
  const [submissions, setSubmissions] = React.useState([])
  const [selectedSubreddit, setSelectedSubreddit] = React.useState("science")

  const getSubcriptions = async () => {
    console.log("Getting subscriptions")
    client
      .getUserSubscriptions()
      .then(user_subscriptions => {
        setSubscriptions(user_subscriptions)
        setSubreddit(selectedSubreddit)
      })
      .then(res => getSubmissions())
  }

  const getSubmissions = async () => {
    console.log(
      "Getting posts for: " +
        selectedSubreddit +
        ", " +
        sortType +
        ", " +
        timeFilter
    )
    client
      .getSubmissions(selectedSubreddit, sortType, timeFilter)
      .then(subreddit_submissions => {
        setSubmissions(subreddit_submissions)
        onUpdate(subreddit_submissions)
      })

    // console.log(reddit_submissions)

    // console.log(submissions)
  }

  const setSubreddit = displayed => {
    console.log("SETTING NEW SUBREDDIT")

    // if (displayed != selectedSubreddit) {
    console.log("New Subreddit selected")
    console.log(
      "Subreddit: " + displayed + ", time: " + timeFilter + ", sort:" + sortType
    )

    setSelectedSubreddit(displayed)
    getSubmissions()

    // }
    // if (newTimeFilter != timeFilter || newSortType != sortType) {
    //   console.log(
    //     "Subreddit: " +
    //       displayed +
    //       ", time: " +
    //       timeFilter +
    //       ", sort:" +
    //       sortType
    //   )
    //   getSubmissions(displayed, newSortType, newTimeFilter)

    // }
  }

  React.useEffect(() => {

    if (client && isloggedin) {
      getSubcriptions()

      console.log(subscriptions)

      // console.log(subs)
      // console.log(subs)

      // setSelectedSubreddit(subscriptions[0].data.display_name)
      // setSubreddit(selectedSubreddit, timeFilter, sortType)
    }
  }, [client, timeFilter, sortType, isloggedin, selectedSubreddit])
  //   }, [])

  const items = subscriptions.map(subreddit => {
    return (
      <MenuItem value={subreddit.data.display_name}>
        {subreddit.data.display_name}
      </MenuItem>
    )
  })

  return (
    <FormControl style={{ minWidth: 240 }}>
      <InputLabel id="subscription-simple-select-label">
        My Subscriptions
      </InputLabel>
      <Select
        labelId="subscription-simple-select-label"
        id="subscription-simple-select"
        value={selectedSubreddit}
        onChange={event => setSubreddit(event.target.value)}
      >
        {items}
      </Select>
    </FormControl>
  )
}

export default UserSubscriptions
