import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Home from  "../components/home"


const IndexPage = () => {
  

  return (
    <Layout>
      {/* <SEO title="Home" />
      <h1>Welcome</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link> */}
      <Home/>
    </Layout>
  )
}

export default IndexPage
