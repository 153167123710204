import React from "react"

import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core"

import LayoutRenderer from "../components/LayoutRenderer"
import UserSubscriptions from "../components/UserSubscriptions"



const SubmissionFilter = ({ filter, onFilterChange }) => {
  const [filterValue, setFilterValue] = React.useState(filter.filters[filter.initial].value)

  const setFilter = newFilterValue => {
    setFilterValue(newFilterValue)
    console.log("Filter set to " + filterValue)
    onFilterChange(filterValue)
  }

  React.useEffect(() => {
    console.log("Setting filter: "+filter.title+" to "+filterValue)
    setFilter(filterValue)    
  })

  const items = filter.filters.map(item => {
    return <MenuItem value={item.value}>{item.title}</MenuItem>
  })

  return (
    <FormControl style={{ minWidth: 120 }}>
      <InputLabel id="time-simple-select-label">{filter.title}</InputLabel>
      <Select
        labelId="time-simple-select-label"
        id="time-simple-select"
        value={filterValue}
        onChange={(event) => setFilter(event.target.value)}
      >
        {items}
      </Select>
    </FormControl>
  )
}

const Home = () => {

  const [data, setData] = React.useState([])
  const [timeFilter, setTimeFilter] = React.useState("day")
  const [typeFilter, setTypeFilter] = React.useState("top")
  const [subreddit, setSubreddit] = React.useState("science")
  const [fieldVal, setFieldVal] = React.useState("")

  // React.useEffect(() => {
  //   // console.log("triggered")
  //   // async function fetchData() {
  //   //   const res = await fetch(
  //   //     "http://127.0.0.1:5000/?subreddit=" +
  //   //       subreddit +
  //   //       "&type=" +
  //   //       typeFilter +
  //   //       "&time=" +
  //   //       timeFilter
  //   //   )
  //   //   res.json().then(res => setData(res))
  //   // }
  //   // fetchData()
  // }, [subreddit, timeFilter, typeFilter])


  // React.useEffect(()=>{
  

  // },[data])


  // React.useEffect(() => {
  //   console.log(data)
  // }, [data])



  const isSubmitted = () => {
    setSubreddit(fieldVal)
  }

  const setTime = (newFilter) => {
    setTimeFilter(newFilter)
  }

  const setType = (newFilter) => {
    setTypeFilter(newFilter)
  }

  const onUpdate = submissions => {
    setData(submissions)
    console.log(data)
  }

  const time_filter = {title: "Time Filter", initial:1, filters: [{value:"hour", title: "Hour"},{value:"day", title: "Day"},{value:"week", title: "Week"},{value:"month", title: "Month"},{value:"year", title: "Year"}]}
  const type_filter = {title: "Type", initial:0, filters: [{value:"top", title: "Top"},{value:"hot", title: "Hot"},{value:"new", title: "New"},{value:"controversial", title: "Controversial"}]}

  return (
    <>
      <Grid
        container
        style={{
          position: "sticky",
          top: 0,
          paddingBottom: "50px",
          paddingTop: "50px",
          marginBottom: "50px",
          backgroundColor: "white",
          borderBottom: "1px #e0e0e0 solid",
          boxShadow: "0 4px 2px -2px gray",
          zIndex: "100",
        }}
      >
        <Grid item md={8}>
          <Grid container justify="center">
            <UserSubscriptions onUpdate={onUpdate} timeFilter={timeFilter} sortType={typeFilter} />
            {/* <TextField
              label="Enter Subreddit"
              // placeholder="Enter Subreddit"
              onChange={e => setFieldVal(e.target.value)}
            />
            <Button variant="outlined" onClick={isSubmitted}>
              Render
            </Button> */}
          </Grid>
        </Grid>
        <Grid item xm={12} md={4}>
          <Grid container justify="flex-start">
            <SubmissionFilter filter={time_filter} onFilterChange={setTime}/>
            <SubmissionFilter filter={type_filter} onFilterChange={setType}/>
          </Grid>
        </Grid>
      </Grid>
      {data ? <LayoutRenderer data={data} /> : <>Loading...</>}
    </>
  )
}

export default Home
