import React from "react"

import { Grid, Typography, Badge } from "@material-ui/core"
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline"
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined"

import Modal from "@material-ui/core/Modal"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

// import Readability from "readability"
// import { extract } from "article-parser"
// import getReaderData from "react-readability";
// import got from 'got'
// import metascraper from 'metascraper'

const ModalDisplay = ({ item, onStateUpdate, state }) => {
  const [modalState, setModalState] = React.useState(state)
  const [content, setContent] = React.useState("")

  const handleClose = () => {
    setContent("")
    setModalState(false)
    onStateUpdate(false)
  }

  const grabPage = async (url) => {
    const response = await fetch("https://readderbackend.embridea.com/article/?url=" + escape(url))
    // console.log(response)
    return response.json()
  }

  React.useEffect(() => {
    // const {
    //   extract
    // } = require('article-parser');

    // const url = 'https://goo.gl/MV8Tkh';

    // extract(url).then((article) => {
    //   console.log(article);
    // }).catch((err) => {
    //   console.log(err);
    // });
    setModalState(state)

    if (item.domain == 'youtube.com'){
      const tokens = item.url.split("=")
      const video_code = tokens[tokens.length-1]
      const embed = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${video_code}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      setContent(embed)
      // setContent(unescape(item.media_embed.content))
    }else{
      grabPage(item.url).then(res => {
        var top_image = ""
        var summary = ""
        if (res.top_image){
          top_image = `<div><img src='${res.top_image}'/></div>`
        }
        if (res.summary){
          summary = `<div><b>Summary:</b><i>${res.summary}</i></div><br/>`
        }
        console.log(top_image)
        setContent(top_image+summary+res.content)
      })

    }

    
    // setState(true)
    // onStateUpdate(open)
  }, [state])

  // const grabReaderData = async function(url) {
  //   const data = await getReaderData({ url });
  //   console.log(data);
  // };

  // const getArticle = async (url) => {
  //   try {
  //     const article = await extract(url);
  //     return article;
  //   } catch (err) {
  //     console.trace(err);
  //   }
  // };


  const body = (
    <div>
      <h2 id="simple-modal-title">"test"</h2>
      <p id="simple-modal-description">{content}</p>
      {/* <ModalDisplay /> */}
    </div>
  )

  return (
    <Dialog
      open={modalState}
      keepMounted
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{unescape(item.title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions>
          <Button onClick={()=>setOpen(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={()=>setOpen(false)} color="primary">
            Agree
          </Button>
        </DialogActions> */}
    </Dialog>
  )
}

const ItemRenderer = ({ item, onOpen }) => {
  var preview_url = ""
  if (item.preview && item.preview.images[0]) {
    preview_url = item.preview.images[0].source.url.replace("&amp;", "&")
  }

  var media_embed = null
  if (item.media && item.media.oembed) {
    console.log("FOUND MEDIA!")

    if (item.domain == 'youtube.com'){
      const tokens = item.url.split("=")
      const video_code = tokens[tokens.length-1]
      const embed = `<iframe src="https://www.youtube.com/embed/${video_code}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      media_embed = embed
      // setContent(unescape(item.media_embed.content))
    }

    //   media_embed = unescape(item.media.oembed.html)
  }

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        style={{ fontSize: "1rem" }}
        onClick={() => onOpen(item)}
      >
        {item.title}
      </Typography>

      <Typography
        variant="subtitle2"
        display="block"
        gutterBottom
        style={{ fontSize: "0.8rem" }}
      >
        <Grid container>
          <Grid item md={9}>
            <a
              href={item.url}
              style={{ textDecoration: "none", color: "grey" }}
            >
              {item.domain}
            </a>
            <Typography style={{ fontSize: "0.7rem", fontStyle:"italic" }}>
            {new Date(item.created*1e3).toLocaleDateString("el")}
            </Typography>
          </Grid>

          <Grid item md={3}>
            <Grid container justify="flex-end">
              {/* <Chip label={data[i].score} color="primary"  style={{fontSize:"0.5rem"}}/>
                          <Chip
                            label={data[i].num_comments}
                            variant="outlined"
                            color="secondary"
                            style={{fontSize:"0.5rem"}}
                          /> */}
              <Badge
                badgeContent={item.score}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                max={10000}
                style={{ padding: "0" }}
              >
                <ThumbUpOutlinedIcon
                  style={{ color: "grey", fontSize: "1.2rem" }}
                />
              </Badge>
              <Badge
                badgeContent={item.num_comments}
                color="primary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                max={10000}
              >
                <ChatBubbleOutlineIcon
                  style={{ color: "grey", fontSize: "1.2rem" }}
                />
              </Badge>
            </Grid>
          </Grid>
        </Grid>
      </Typography>

      <Grid container justify="center">
        {/* <img src={data[i].thumbnail} /> */}
        {media_embed ? <div dangerouslySetInnerHTML={{ __html: media_embed }} /> : <img src={preview_url} />}
      </Grid>
      <Typography variant="body2" gutterBottom>
        {item.selftext.substring(0, 200)}
      </Typography>
    </>
  )
}

const LayoutRenderer = ({ data }) => {
  const [submissions, setSubmissions] = React.useState([])
  const [modalState, setModalState] = React.useState(false)
  const [modalItem, setModalItem] = React.useState({ title: "", url: "" })

  const max_columns = 12
  const total_cols = 3
  const total_cols_sm = 2
  var count = 0
  var size_multiplier = max_columns / total_cols
  var size_multiplier_sm = max_columns / total_cols_sm
  var new_row = 1
  var values = []

  const calculate = () => {
    const rand_num = Math.floor(Math.random() * total_cols) + 1 // returns a random integer from 1 to 10

    const same_cols_size = ~~(total_cols / rand_num)
    const extra_col_size = total_cols % rand_num

    values = [rand_num, same_cols_size, extra_col_size]

    new_row = 0
  }

  const onItemOpen = item => {
    setModalItem(item)
    setModalState(true)
  }

  const onModalStateUpdate = (state) => {
    setModalItem(modalItem)
    setModalState(state)
  }

  React.useEffect(() => {
    // console.log("Data to Render: ")
    // console.log(submissions)
    console.log("Updating layout")
    console.log(data)
    setSubmissions(data)
    //   const test = submissions.map(item => console.log(item.data.title) )
  }, [data])

  const renderItem = (item, index) => {
    // console.log("Item: " + index)
    // console.log("Count: " + count)

    if (new_row) {
      //   console.log("New row start: ")
      calculate()
    }

    var size = values[1] * size_multiplier
    var size_sm = values[1] * size_multiplier_sm

    if (count < values[0]) {
      size = values[1] * size_multiplier
      count++
    } else {
      //   console.log("Last_item")
      size = values[2] * size_multiplier
      size_sm = values[2] * size_multiplier_sm
      //   console.log("Size: " + size)
      count = 0
      new_row = 1
    }

    return (
      <Grid item xs={12} sm={size_sm} md={size}>
        <ItemRenderer item={item} onOpen={onItemOpen} />
      </Grid>
    )
  }

  const items = submissions.map((item, index) => renderItem(item.data, index))

  return (
    <Grid container spacing={6}>
      {items}
      <ModalDisplay onStateUpdate={onModalStateUpdate} item={modalItem} state={modalState} />
    </Grid>
  )
}

export default LayoutRenderer
